body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

a {
  outline: 0 !important;
}

.no-preload-transition * {
  transition: none !important;
}

h1,
h2 {
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Raleway", sans-serif;
}

h1,
aside #link-list .con {
  color: black;
  opacity: 0.7;
}

aside {
  min-height: 40vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

aside>div {
  padding: 2rem;
}

aside>div>p {
  font-family: "Raleway", sans-serif;
}

aside>div>h1 {
  font-size: 2rem;
}

aside>div>picture>img {
  width: 40%;
  height: 40%;
  border-radius: 50%;
  animation: shake 500ms ease-in-out;
  animation-delay: 500ms;
}

aside>div>picture>img:hover {
  animation: shake 500ms;
  animation-iteration-count: infinite;
}

aside #link-list .icon {
  width: 2rem;
  height: 2rem;
  opacity: 0.7;
  margin-right: 1.5rem;
  padding: 0.5rem 0;
  transition: ease-in-out 100ms;
}

aside #link-list {
  margin-top: 2rem;
}

@media (pointer: fine) {
  aside #link-list .icon:hover {
    transform: scale(1.5);
    transition: ease-in-out 100ms;
  }
}

main {
  min-height: 70vh;
  width: 100%;
}

main>section {
  padding: 2rem;
  color: white;
  margin-bottom: 1rem;
}

main>section:nth-child(3n + 1) {
  background-color: #3b587d;
}

main>section:nth-of-type(3n + 2) {
  background-color: #6d597a;
}

main>section:nth-of-type(3n + 3) {
  background-color: #ab6a78;
}

main>section>h2 {
  font-size: 2rem;
  margin-top: 1rem;
}

main>section>div {
  padding-bottom: 3rem;
}

main>section>div h3 {
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.2;
  font-weight: 800;
  font-family: "Raleway", sans-serif;
}

main>section>div>h3>a .icon {
  height: 1rem;
  padding: 0 0.5rem 0 0.7rem;
  opacity: 0.3;
  margin-bottom: -1px;
}

main>section>div>h3>a .icon:hover {
  opacity: 1.0;
}

main>section>div>p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.3rem;
  font-weight: 300;
}

main>section>div>p:last-child {
  font-size: 0.8rem;
}

main>section>div>div:last-child {
  margin-top: 0.4rem;
  padding-left: 0.1rem;
  display: flex;
  flex-wrap: wrap;
}

.project-tag {
  font-size: 0.8rem;
  margin: 0 0.6rem 0.4rem 0;
  padding: 0.2rem 0.4rem;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  white-space: nowrap;
}

main>section>div>p:first-child {
  font-family: "Raleway", sans-serif;
}

main>section.has-timeline>div {
  position: relative;
  padding-left: 2rem;
  margin-left: 0.6rem;
}

main>section.has-timeline>div::before {
  content: "";
  height: 1.2rem;
  width: 1.2rem;
  background-color: white;
  margin-left: -0.7rem;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 2.5rem;
  left: 0;
}

main>section.has-timeline>div {
  border-left: 0.2rem solid white;
  padding-top: 1rem;
}

main>section.has-timeline>div:last-of-type {
  padding-top: 0;
  padding-bottom: 1rem;
}

main>section.has-timeline>div:last-of-type::before {
  top: 1.5rem;
}

main>div:last-child {
  text-align: left;
  color: #444;
  padding: 2rem;
}

#footer>a,
#footer>a:focus,
#footer>a:active,
#footer>a:hover {
  color: #444;
  text-decoration: none;
}

@media only screen and (min-width: 800px) {
  aside {
    height: 100vh;
    position: fixed;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 span {
    box-decoration-break: clone;
    line-height: 130%;
  }

  h1 span.blue {
    background: linear-gradient(to top, #3b587d 35%, transparent 35%);
  }

  h1 span.violet {
    background: linear-gradient(to top, #6d597a 35%, transparent 35%);
  }

  h1 span.red {
    background: linear-gradient(to top, #ab6a78 35%, transparent 35%);
  }

  main {
    min-height: 70vh;
    width: 60%;
    max-width: 1200px;
    margin-left: 40%;
  }

  main>section,
  aside>div {
    padding: 3rem;
  }

  main>section>div>p,
  main>section>div>div:last-child {
    max-width: 900px;
  }

  aside>div>h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  main>section {
    margin: 1rem;
  }

  #footer {
    text-align: right;
    padding: 1rem 1.1rem 0.5rem 3rem;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}